<template>
  <BAlert
    variant="danger"
    :show="hasLoadError"
  >
    <h4 class="alert-heading">
      Error fetching {{ subject }} data
    </h4>
    <div class="alert-body">
      No {{ subject }} found with this {{ subject }} id. Check
      <BLink
        class="alert-link"
        :to="to"
      >
        {{ subject }} list
      </BLink>
      for other {{ subject }}s.
    </div>
  </BAlert>
</template>

<script>
import {
  BAlert,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BLink,
  },
  props: {
    hasLoadError: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: String,
      default: '',
    },
    to: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
