<template>
  <BFormInput
    v-if="!disabled"
    :value="value"
    class="tagby-hyperlink"
    readonly
    @click="linkTo"
  />
  <BFormInput
    v-else
    :value="value"
    readonly
  />
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'
import router from '@/router'

export default {
  components: {
    BFormInput,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    to: {
      type: Object,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    linkTo() {
      if (this.to != null) {
        router.push(this.to)
      } else {
        window.open(this.href, '_blank')
      }
    },
  },
}
</script>

<style>
.tagby-hyperlink {
  font-weight: bold;
  color: var(--primary) !important;
}
.tagby-hyperlink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
