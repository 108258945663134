<template>
  <TagbyDetailLayout>
    <template #error>
      <TagbyLoadError
        :hasLoadError="hasLoadError"
        subject="Job Log"
        :to="{ name: 'posting-ranking-log-list' }"
      />
    </template>
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(default)>
          <DefaultInfoCard />
        </template>

        <template #tab(postings)>
          <PostingListCard />
        </template>

        <template #tab(file)>
          <ScreenshotCard />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useInitialize, useDefault } from './viewModel'
import TagbyLoadError from '@/components/TagbyLoadError.vue'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import TagbyTabs from '@/components/TagbyTabs.vue'
import DefaultInfoCard from './components/DefaultInfoCard.vue'
import PostingListCard from './components/PostingListCard.vue'
import ScreenshotCard from './components/ScreenshotCard.vue'
import ActionCard from './components/ActionCard.vue'

export default defineComponent({
  components: {
    TagbyLoadError,
    TagbyDetailLayout,
    TagbyTabs,

    DefaultInfoCard,
    PostingListCard,
    ScreenshotCard,
    ActionCard,
  },
  setup() {
    const { initialize } = useInitialize()
    initialize()

    const {
      hasLoadError,
      jobIdx,
      campaignIdx,
      searchStr,
      logTab,
      logRun,
      postingList,
      tabList,
      fetchDetail,
    } = useDefault()
    fetchDetail()

    return {
      hasLoadError,
      jobIdx,
      campaignIdx,
      searchStr,
      logTab,
      logRun,
      postingList,
      tabList,
    }
  },
})
</script>
