import axios from '@axios'

export default function (urlOrBlob, name = '', responseType = 'blob', save = true) {
  if (urlOrBlob instanceof Blob || urlOrBlob instanceof File) {
    const linkEl = document.createElement('a')
    linkEl.href = window.URL.createObjectURL(urlOrBlob)
    linkEl.download = name
    linkEl.click()
    return new Promise(resolve => resolve())
  }
  const lastSlashIndex = urlOrBlob.lastIndexOf('/')
  const fileName = name ?? urlOrBlob.slice(lastSlashIndex + 1)
  return new Promise((resolve, reject) => {
    axios.get(urlOrBlob, {
      responseType,
      transformRequest: (data, headers) => {
        const { common } = headers
        delete common.Authorization
        return data
      },
    }).then(response => {
      const { data } = response
      if (save) {
        const linkEl = document.createElement('a')
        linkEl.href = window.URL.createObjectURL(data)
        linkEl.download = fileName
        linkEl.click()
      }
      resolve(data)
    }).catch(error => {
      reject(error)
    })
  })
}
