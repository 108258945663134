import axios from '@axios'

const STORE_MODULE_NAME = 'posting-ranking-log'
const actions = {
  fetchItems(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/posting-ranking-log/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  searchItems(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/posting-ranking-log/search-items/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchDetail(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/posting-ranking-log/get-detail/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
