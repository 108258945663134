import {
  computed,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'

export default function (STORE_MODULE_NAME, tempStoreModule) {
  const assistState = computed(() => store.state[STORE_MODULE_NAME])
  const state = name => computed(() => assistState.value?.[name])
  const getters = name => computed(() => store.getters[`${STORE_MODULE_NAME}/${name}`])
  const commit = (name, payload) => store.commit(`${STORE_MODULE_NAME}/${name}`, payload)
  const dispatch = (name, payload) => store.dispatch(`${STORE_MODULE_NAME}/${name}`, payload)
  const setValue = (name, value) => commit('setValue', {
    name,
    value,
  })

  const allStateNameList = computed(() => Object.keys(assistState.value))
  const assistMap = processing => (nameList => computed(() => (nameList ?? allStateNameList.value).reduce((p, c) => ({ ...p, [c]: processing(c) }), {})).value ?? {})
  const mapState = assistMap(state)
  const mapGetters = assistMap(getters)
  const mapActions = assistMap(name => (payload => dispatch(name, payload)))

  const registerModule = storeModule => {
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule ?? tempStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })
  }

  return {
    state,
    getters,
    commit,
    dispatch,

    setValue,

    mapState,
    mapGetters,
    mapActions,

    registerModule,
  }
}
