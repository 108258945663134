import {
  ref,
  computed,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useStore from '@/utils/store'
import useToast from '@/utils/toast'
import resolveValue from '@/utils/resolveValue'
import downloadFile from '@/utils/downloadFile'
import postingRankingLogStoreModule from '@/store/tagby/postingRankingLog'

const routerIdx = ref()

export function useInitialize() {
  const initialize = () => {
    routerIdx.value = router.currentRoute.params.idx

    if (!store.hasModule(postingRankingLogStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(postingRankingLogStoreModule.STORE_MODULE_NAME, postingRankingLogStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(postingRankingLogStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(postingRankingLogStoreModule.STORE_MODULE_NAME)
      }
    })
  }

  return {
    initialize,
  }
}

const hasLoadError = ref(false)
const jobIdx = ref()
const campaignIdx = ref()
const searchStr = ref()
const logTab = ref()
const logRun = ref()
const campaignState = ref()
const postingList = ref([])
const tabList = [
  { key: 'default', title: '기본' },
  { key: 'postings', title: '포스팅' },
  { key: 'file', title: '스크린샷' },
]
const imgSrc = ref()

export function useDefault() {
  const { dispatch } = useStore(postingRankingLogStoreModule.STORE_MODULE_NAME)
  const fetchDetail = () => {
    dispatch('fetchDetail', {
      idx: routerIdx.value,
    }).then(response => {
      const defaultData = response.data.data.default
      jobIdx.value = defaultData.job_idx
      campaignIdx.value = defaultData.campaign_idx
      searchStr.value = defaultData.search_str
      logTab.value = defaultData.tab
      logRun.value = defaultData.run
      campaignState.value = response.data.data.campaign_state
      postingList.value = response.data.data.postings
      imgSrc.value = response.data.data.file?.url
    }).catch(() => {
      hasLoadError.value = true
    }).finally(() => {

    })
  }

  return {
    hasLoadError,
    jobIdx,
    campaignIdx,
    searchStr,
    logTab,
    logRun,
    campaignState,
    postingList,
    tabList,
    imgSrc,

    fetchDetail,
  }
}

export function usePostingList() {
  const fields = [
    { key: 'sns', label: 'sns' },
    { key: 'url', label: 'url' },
    { key: 'state', label: 'state' },
    { key: 'ranking', label: 'ranking' },
  ]

  const resolveLogIdx = item => item.job_log?.idx
  const resolveLogRanking = item => (item.job_log?.ranking === -1 ? null : item.job_log?.ranking)
  return {
    fields,
    postingList,
    resolveLogIdx,
    resolveLogRanking,
  }
}

export function useImgDownload() {
  const hasImgFile = computed(() => imgSrc.value != null)
  const downloadImg = () => {
    downloadFile(imgSrc.value)
  }
  return {
    hasImgFile,
    downloadImg,
  }
}
