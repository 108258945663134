<template>
  <BCard title="기본 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="JOB_IDX">
          <TagbyLinkInput
            :value="jobIdx"
            :to="{ name: 'screenshot-job-detail', params: { idx: jobIdx } }"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="CAMPAIGN_IDX">
          <TagbyLinkInput
            v-if="campaignState === 'HIDDEN'"
            :value="jobIdx"
            :to="{ name: 'campaign-compt-hidden-detail', params: { idx: campaignIdx } }"
          />
          <TagbyLinkInput
            v-else
            :value="jobIdx"
            :to="{ name: 'campaign-compt-active-detail', params: { idx: campaignIdx } }"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="SEARCH_STR">
          <BFormInput
            :value="searchStr"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol cols="4">
        <BFormGroup label="RUN">
          <BFormInput
            :value="logRun"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="TAB">
          <BFormInput
            :value="logTab"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import { useDefault } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,

    TagbyLinkInput,
  },
  setup() {
    const {
      jobIdx,
      campaignIdx,
      searchStr,
      logTab,
      logRun,
      campaignState,
    } = useDefault()

    return {
      jobIdx,
      campaignIdx,
      searchStr,
      logTab,
      logRun,
      campaignState,
    }
  },
})
</script>
