<template>
  <BCard title="포스팅 리스트">
    <BTable
      :fields="fields"
      :items="postingList"
      responsive
      show-empty
    >
      <template #cell(sns)="data">
        <TagbySnsColumn :sns="data.item.sns" />
      </template>

      <template #cell(url)="data">
        <BLink
          :href="data.value"
          target="_blank"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(log_idx)="data">
        {{ resolveLogIdx(data.item) }}
      </template>

      <template #cell(ranking)="data">
        {{ resolveLogRanking(data.item) }}
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import { usePostingList } from '../viewModel'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    TagbySnsColumn,
  },
  setup() {
    const {
      fields,
      postingList,
      resolveLogIdx,
      resolveLogRanking,
    } = usePostingList()

    return {
      fields,
      postingList,
      resolveLogIdx,
      resolveLogRanking,
    }
  },
})
</script>
