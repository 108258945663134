<template>
  <BButton
    variant="outline-secondary"
    block
    :disabled="!hasImgFile"
    @click="downloadImg"
  >
    스크린샷 다운로드
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
} from 'bootstrap-vue'
import { useImgDownload } from '../viewModel'

export default defineComponent({
  components: {
    BButton,
  },
  setup() {
    const {
      hasImgFile,
      downloadImg,
    } = useImgDownload()

    return {
      hasImgFile,
      downloadImg,
    }
  },
})
</script>
